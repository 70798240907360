import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 15,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  innerWrapper: {
    width: "100%",
    maxWidth: 1440,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  integrationDiagramImg: {
    width: "50%",
  },
  description: {
    marginTop: 40,
    maxWidth: 1300
  },
  integrationProcessListWrapper: {
    marginTop: 40,
  },
  item: {
    //
  },
  itemInnerWrapper: {
    position: "relative",
    height: "100%",
    minHeight: 150,
    backgroundColor: "#f0f0f0",
    padding: "40px"
  },
  itemTagImage: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "30%",
  },
  itemTitle: {
    //color: "#2b67b1",
    textAlign: "center",
  },
  itemDescription: {
    marginTop: 10,
    textAlign: "left",
    fontSize: "1rem",
    lineHeight: 1.5
  },
}))
