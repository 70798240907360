import * as React from "react"
import { Typography, Grid } from "@material-ui/core"

import systemsIntegrationGraphicImg from "../../../../media/services/systems-integrations-graphic.svg"
import smallGrayTriangleImg from "../../../../media/blue-small-triangle.png"

import { integrationProcesses } from "./data"
import { useStyles } from "./styles"

const description = "Whether new or old, we'll integrate your various data sources to provide a centralized, single source of truth that enables you to automate and simplify processes, and improve the flow of quality data across your organization. Our process includes:"

const IntegrationProcesses = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        <img src={systemsIntegrationGraphicImg} className={classes.integrationDiagramImg} />
        <Typography variant="body2" className={classes.description}>
          {description}
        </Typography>
        <Grid container spacing={10} className={classes.integrationProcessListWrapper}>
        {integrationProcesses.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}
            key={`integration-process-${index}`}
            className={classes.item}
          >
            <div className={classes.itemInnerWrapper}>
              <img src={smallGrayTriangleImg} className={classes.itemTagImage} />
              <Typography variant="subtitle2" className={classes.itemTitle}>
                {item.title}
              </Typography>
              <Typography variant="body2" className={classes.itemDescription}>
                {item.description}
              </Typography>
            </div>
          </Grid>
        ))}
        </Grid>
      </div>
    </div>
  )
}

export default IntegrationProcesses
