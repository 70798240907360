export const integrationProcesses = [
  {
    title: "Requirements and discovery",
    description: "Understand the business situation, pain points, desired outcomes, technology features, and success criteria",
  },
  {
    title: "Current state analysis",
    description: "Inventory existing data sources, process flows, reporting outputs, and end consumers",
  },
  {
    title: "Target operating model",
    description: "Define a target state operating model that supports your business now and into the future",
  },
  {
    title: "Gap analysis",
    description: "Based on the current and target state, identify areas of focus to achieve short term \"quick-wins\" and the long-term strategic vision",
  },
  {
    title: "Architecture",
    description: "With a keen eye on timing, availability, and quality of data, create a logical data flow, map data elements from source to target, and define hierarchy, translation, and other business rules",
  },
  {
    title: "Execution",
    description: "Physically connect disparate systems by creating and testing automated data feeds, facilitate user acceptance testing (UAT), and provide training to ensure user adoption",
  },
]
