import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import SubHero from "../../components/SubHero"
import Introduction from "../../components/Introduction"
import IntegrationProcesses from "../../sections/services/IntegrationProcesses"
import LearnHow from "../../components/LearnHow"
import NextService from "../../sections/services/NextService"

import systemsIntegrationBgUrl from "../../../media/system-integration-banner-overlay.jpg"

const systemsIntegrationTitle = "Systems Integration Services"
const systemsIntegrationDescription = ""
const introductionTitle = "Accelerate informed decision-making with accurate and timely data"
const introductionPara1 = "Disconnected technology systems lead to fragmented data, teams, and operations, which can increase costs, drain resources, and affect decision-making. We know the value an integrated data ecosystem brings to financial services firms. Grandview integrates market data providers with Investment Book of Records (IBOR) and Accounting Book of Records (ABOR) to ensure data flows fluidly across all technology components and business processes. Our systems integration services help reduce IT complexity and increase the return on your technology and market data investments."
const introductionPara2 = ""
const para = [
  introductionPara1,
  introductionPara2
]
const learnHowTitle = "Read the case study"
const learnHowDescription = "Learn how an asset manager integrated data from 20 providers and saved more than $1 million by eliminating redundant data sources."
const learnHowLink = "case-study/system-integration"

const SystemsIntegrationPage = ({ location }: PageProps) => (
  <Layout location={location}>
    <SEO title={systemsIntegrationTitle} />
    <SubHero
      backgroundImage={systemsIntegrationBgUrl}
      title={systemsIntegrationTitle}
      description={systemsIntegrationDescription}
    />
    <Introduction
      title={introductionTitle}
      introduction={para}
    />
    <IntegrationProcesses />
    <LearnHow
      title={learnHowTitle}
      description={learnHowDescription}
      to={learnHowLink}
    />
    <NextService
      text="Next, learn about our Data and Analytics services"
      link="services/data-and-analytics"
    />
  </Layout>
)

export default SystemsIntegrationPage
